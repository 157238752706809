import React from 'react';
import { Card, CardContent, CardMedia, Typography, Container, Grid} from '@mui/material';
import useFadeInOnScroll from '../effects/useFadeInOnScroll';
import './fadeInLeftComponent.css'
import './Home.css'
import TypingEffectComponent from '../effects/TypingEffectComponent/TypingEffectComponent';

const Home = () => {
  // クラス名を指定してフックを呼び出す
  useFadeInOnScroll('fade-in-left');

  return (
      <div className="home-background">
        {/* <Container style={{ width: '100%',height: '300px' }}>
            <Grid container style={{ width: '100%',height: '100%' }}>
                <Grid item xs={12} sm={6} style={{ width: '100%',height: '100%' }}>
                    <TypingEffectComponent />
                </Grid>
                <Grid item xs={12} sm={6} style={{ width: '100%',height: '100%' }}>
                  <div className='image-container'>
                    <img src="/imgs/main_img.png"/>
                  </div>
                </Grid>
            </Grid>
        </Container> */}
        <div>
          {/* <Container>
            <Typography variant="h4" gutterBottom>
                AI Service Development
              </Typography>
              <Typography variant="body1" paragraph>
                最先端の生成AI技術を使用してサービスを開発しています。以下は私たちの主要な製品の一部です。
              </Typography>
            </Container> */}
        </div>
        <div>
        {/* <Container style={{'backgroundColor':'fafbff'}}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image="/imgs/logo.png"
                  alt="Product 1"
                />
                <CardContent>
                  <Typography variant="h6">LINEBOT x ChatGPT</Typography>
                  <Typography variant="body2">製品1の詳細な説明...</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image="/imgs/logo.png"
                  alt="Product 2"
                />
                <CardContent>
                  <Typography variant="h6">製品2</Typography>
                  <Typography variant="body2">製品2の詳細な説明...</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image="/imgs/logo.png"
                  alt="Product 3"
                />
                <CardContent>
                  <Typography variant="h6">製品3</Typography>
                  <Typography variant="body2">製品3の詳細な説明...</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container> */}
        </div>
      </div>
  );
};

export default Home;
