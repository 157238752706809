import React from 'react';
import { Container, Typography, Link, Box, IconButton } from '@mui/material';
import styled from '@emotion/styled';

const StyledFooter = styled.footer`
  background-color: white; // Material-UIのデフォルトのprimary色
  color: black;
  padding: 20px 0;
  margin-top: auto;
`;

const Logo = styled.img`
  width: 300px; // ロゴのサイズを調整
  margin-bottom: 10px;
`;

const Footer = () => (
  <StyledFooter>
    <Container maxWidth="md">
      <Box display="flex" flexDirection="column" alignItems="center">
        <IconButton>
          <Logo src="/imgs/logo.png" alt="Company Logo" />
        </IconButton>
        <Typography variant="body2" align="center">
          © {new Date().getFullYear()} st4system Co., Ltd. All rights reserved.
        </Typography>
        <Link href="/privacy-policy" color="inherit">
          プライバシーポリシー
        </Link>
      </Box>
    </Container>
  </StyledFooter>
);

export default Footer;
