import React from 'react';
import { Paper, Container, Typography } from '@mui/material';

const CompanyProfile = () => {
  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      {/* <Paper elevation={3} style={{ padding: '2rem', backgroundColor: '#f7f7f7' }}>
        <Typography variant="h4" gutterBottom>
          会社概要
        </Typography>
        <Typography variant="h6" gutterBottom>
          会社名
        </Typography>
        <Typography variant="body1" paragraph>
          株式会社st4system
        </Typography>
        <Typography variant="h6" gutterBottom>
          事業内容
        </Typography>
        <Typography variant="body1" paragraph>
          生成AIを活用したチャットボットサービス開発を中心としたWEBサービス開発
        </Typography>
        <Typography variant="h6" gutterBottom>
          代表者
        </Typography>
        <Typography variant="body1" paragraph>
          前田　智
        </Typography>
        <Typography variant="h6" gutterBottom>
          設立
        </Typography>
        <Typography variant="body1" paragraph>
          2023年4月
        </Typography>
        <Typography variant="h6" gutterBottom>
          連絡先
        </Typography>
        <Typography variant="body1" paragraph>
          [連絡先情報]
        </Typography>
      </Paper> */}
    </Container>
  );
};

export default CompanyProfile;
