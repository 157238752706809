import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Drawer, List, ListItem, ListItemText, Hidden, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const items = [
    { label: "ホーム", href: "/", shouldHide: false },
    { label: "製品", href: "/products", shouldHide: false },
    { label: "事例紹介", href: "/cases", shouldHide: false },
    { label: "会社概要", href: "/about", shouldHide: false },
    { label: "お問い合わせ", href: "/contact", shouldHide: false },
    { label: "採用情報", href: "/careers", shouldHide: true },
    { label: "ニュース/ブログ", href: "/blog", shouldHide: true }
  ];
  
  const menuItems = (
    <>
      {items.map(item => (
        <Button
          key={item.href}
          style={{ color: 'black', display: item.shouldHide ? 'none' : 'block' }}
          href={item.href}
        >
          {item.label}
        </Button>
      ))}
    </>
  );
  
  const drawerItems = (
    <List>
      {items.map(item => (
        <ListItem
          key={item.href}
          component="a"
          href={item.href}
          onClick={handleDrawerClose}
          style={{ display: item.shouldHide ? 'none' : 'block' }}
        >
          <ListItemText primary={item.label} />
        </ListItem>
      ))}
    </List>
  );
  

  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <Hidden mdUp>
          <IconButton edge="start" color="primary" aria-label="menu" onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
            {drawerItems}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          {menuItems}
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
