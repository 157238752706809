import React, { useEffect, useRef, useState } from 'react';
import './fadeInLeftComponent.css';

const FadeInLeftComponent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setIsVisible(entry.isIntersecting));
    });

    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <div 
      className={`fade-in-left ${isVisible ? 'active' : ''}`} 
      ref={domRef}
    >
      こちらの要素は左からフェードインします。
    </div>
  );
};

export default FadeInLeftComponent;
