import React from 'react';
import { Button, TextField, Paper, Container } from '@mui/material';

const ContactForm = () => {
  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      {/* <Paper elevation={3} style={{ padding: '2rem', backgroundColor: '#f7f7f7' }}>
        <h2>Contact Us</h2>
        <form noValidate autoComplete="off">
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            variant="outlined"
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            variant="outlined"
          />
          <TextField
            fullWidth
            margin="normal"
            label="Subject"
            variant="outlined"
          />
          <TextField
            fullWidth
            margin="normal"
            label="Message"
            variant="outlined"
            multiline
            rows={4}
          />
          <Button variant="contained" color="primary" style={{ marginTop: '1rem' }}>
            Submit
          </Button>
        </form>
      </Paper> */}
    </Container>
  );
};

export default ContactForm;
