import React from 'react';
import { Route, Routes } from 'react-router-dom'; // Switch を Routes に変更
import Home from './components/pages/Home'; // ホームページのインポート
import ContactForm from './components/pages/ContactForm'; // お問い合わせページのインポート
import CompanyProfile from './components/pages/ComponyProfile';
import FadeInLeftComponent from './components/pages/FadeInLeftComponent';

const App = () => {
  return (
    <Routes> {/* <Switch> を <Routes> に変更 */}
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<ContactForm />} />
      <Route path="/about" element={<CompanyProfile />} />
      <Route path="/fade-in-left" element={<FadeInLeftComponent />} />
      {/* 他のルーティングもここに追加できます */}
    </Routes>
  );
};

export default App;

